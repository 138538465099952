<template>
  <transition
      mode="out-in"
      name="fade">
    <div
        v-if="!$store.state.showLoader"
        key="normalView">
      <div
          id="employeeTimeCard"
          class="workDay__input__container__dark">
        <div
            id="shiftSelection"
            v-if="shiftsToday.length"
            class="workDay__input__container">
          <h2
              v-text="$t('workDay.yourShiftsToday')"
              class="text-center"
          />
          <b-row
              align-h="center"
              class="mt-2 mb-4">
            <b-col
                cols="auto"
                align="center">
              <b-btn
                  :id="`shiftSelection-${_shift._id}`"
                  v-for="(_shift, index) in shiftsToday"
                  :key="index"
                  @click="viewShift = index"
                  :variant="viewShift === index ? 'info' : 'info-outline'"
                  class="m-2 py-2 px-5">
                <span
                    v-text="_shift.time && _shift.time.start ? displayTime(now, _shift.time.start) : $t('workDay.noShift')"
                />
              </b-btn>
            </b-col>
          </b-row>
          <b-row
              align-h="center">
            <b-col
                cols="*"
                align="center"
                class="workDay__timecard__container px-5 pb-3">
              <h3
                  v-text="displayShiftDate(now)"
                  class="workDay__timecard__header"
              />
              <transition-group
                  name="fade"
                  mode="out-in">
                <b-row
                    key="time"
                    v-if="shift.time && (shift.time.start || shift.time.end)"
                    align-h="center"
                    class="mb-4">
                  <b-col
                      cols="*"
                      align="center"
                      class="workDay__input__container__dark"
                      v-if="shift.time && shift.time.start">
                    <b
                        v-text="$t('workDay.shiftStartsAt')"
                    />
                    <br />
                    <b
                        class="px-3 largeText"
                        v-text="displayTime(now, shift.time.start)"
                    />
                  </b-col>
                  <b-col
                      cols="*"
                      align="center"
                      v-if="shift.time && shift.time.end"
                      class="workDay__input__container__dark">
                    <b
                        v-text="$t('workDay.shiftEndsAt')"
                    />
                    <br />
                    <b
                        v-html="displayTime(now, shift.time.end)"
                        class="px-3 largeText"
                    />
                  </b-col>
                </b-row>
                <b-row
                    key="projectInfo"
                    v-if="shift.project || (shift.projectData && (shift.projectData.name || shift.projectData.title || shift.projectData.address.street))"
                    align-h="center"
                    class="my-3">
                  <b-col
                      cols="*"
                      align="center"
                      class="my-auto">
                    <div
                        class="workDay__input__container__dark">
                      <b
                          v-text="$t('workDay.shiftProject')"
                      />
                      <display-project-info
                          :shiftInfo="shift"
                          class="mt-2 px-3"
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row
                    key="shiftNotes"
                    v-if="shift.notes"
                    align-h="center"
                    class="my-2">
                  <b-col
                      cols="*"
                      align="center">
                    <div
                        class="workDay__input__container__dark">
                      <b
                          v-text="$t('workDay.shiftNotes')"
                      />
                      <br />
                      <b
                          v-text="shift.notes"
                          class="px-3 largeText"
                      />
                    </div>
                  </b-col>
                </b-row>
                <!-- <h3
                    key="noScheduleHeader"
                    v-if="shift.anytimeLogin"
                    v-text="$t('workDay.anytimeLogin')"
                    class="workDay__input__container__dark my-3"
                /> -->
                <div
                    v-for="signIn in signInsToday"
                    :key="signIn._id"
                    class="workDay__input__container__dark py-3">
                  <span
                      v-html="displayTimecardPunch(signIn, true)"
                  />
                  <div
                      v-if="signIn.clockOut">
                    <p />
                    <span
                        v-html="displayTimecardPunch(signIn)"
                    />
                    <p />
                    <span
                        v-html="displayHoursWorked(signIn)"
                    />
                  </div>
                </div>
              </transition-group>
            </b-col>
          </b-row>
          <transition
              name="fade"
              mode="out-in">
            <b-row
                id="addNotes"
                align-h="center"
                class="mt-4 mb-2"
                v-if="!justPunched && shift">
              <transition
                  name="fade"
                  mode="out-in">
                <b-col
                    v-if="addClockInNotes"
                    align="center"
                    cols="auto">
                  <b-form-textarea
                      id="clockInNoteEntry"
                      v-if="!openLogin"
                      v-model="clockInNotes"
                  />
                  <b-form-textarea
                      id="clockInNoteEntry"
                      v-if="openLogin"
                      v-model="clockOutNotes"
                  />
                </b-col>
              </transition>
              <b-col
                  align="center"
                  cols="auto">
                <b-btn
                    id="addClockInNotesbutton"
                    v-if="clockInNow || clockInAdvance"
                    @click="addNotes()"
                    size="sm"
                    variant="info"
                    class="my-auto">
                  <b-icon-pencil-square />
                  <small
                      v-text="!addClockInNotes ? $t('workDay.clockInNotes') : $t('cancel')"
                  />
                </b-btn>
              </b-col>
            </b-row>
          </transition>
          <b-row
              id="autoPunchForLunch"
              align-h="center"
              v-if="showLunchOption">
            <b-col
                cols="*"
                align="center"
                class="workDay__container">
              <b-input-group>
                <b-form-checkbox
                    id="punchForLunchToggle"
                    switch
                    size="lg"
                    class="ml-auto mr-1"
                    v-model="autoPunchLunch"
                />
                <b-input-group-append>
                  {{$t('workDay.autoPunchLunch')}}
                </b-input-group-append>
              </b-input-group>
              <div
                  v-if="autoPunchLunch">
                  {{$t('start')}}:
                  <b-form-timepicker
                      v-model="autoPunchedLunch.start"
                  />
                  {{$t('end')}}:
                  <b-form-timepicker
                      v-model="autoPunchedLunch.end"
                  />
              </div>
            </b-col>
          </b-row>
          <b-row
              id="activeShift"
              align-h="center"
              class="mt-4 mb-2"
              v-if="shift">
            <b-col
                align="center"
                cols="auto">
              <b-btn
                  :disabled="justPunched"
                  id="punchTimecardNow"
                  v-if="clockInNow"
                  @click="!justPunched ? punchTimecard(shift) : cancelPunch(shift)"
                  :variant="!justPunched ? 'primary' : 'info'">
                <div
                    v-if="!justPunched">
                  <b-icon-clock />
                  {{!openLogin ? $t('workDay.timecardButton[0]') : $t('workDay.timecardButton[1]')}} {{$t('workDay.timecardButton[2]')}} ({{displayTime()}})
                </div>
                <div
                    v-else>
                  <b-icon-check />
                  {{openLogin ? $t('workDay.clockedIn') : $t('workDay.clockedOut')}}
                </div>
              </b-btn>
            </b-col>
            <b-col
                align="center"
                cols="auto">
              <b-btn
                  id="punchTimecardAdvance"
                  v-if="clockInAt"
                  @click="!justPunched ? punchTimecard(shift, true) : cancelPunch(shift)"
                  :variant="!justPunched ? 'primary' : 'info'">
                <div
                    v-if="!justPunched">
                  <b-icon-clock />
                  {{$t('workDay.timecardButton[0]')}} {{$t('workDay.timecardButton[3]')}} {{displayTime(now, shift.time.start)}}
                </div>
                <div
                    v-else>
                  <b-icon-check />
                  {{openLogin ? $t('workDay.cancelClockIn') : $t('workDay.cancelClockOut')}}
                </div>
              </b-btn>
            </b-col>
          </b-row>
        </div>
        <div
            v-else
            class="workDay__input__container__dark">
          <h2
              v-text="$t('workDay.noShiftsToday')"
              class="text-center py-4"
          />
        </div>
      </div>
      <div
          id="futureShifts"
          v-if="shiftsInTheFuture.length"
          class="workDay__input__container__dark mt-5">
        <div
            class="workDay__input__container">
          <h2
              class="text-center">
            {{shiftsInTheFuture.length}} {{$t('workDay.yourShiftsFuture')}}
            <b-btn
                @click="showFutureShifts = !showFutureShifts">
              <b-icon-eye
                  v-if="!showFutureShifts"
                  :aria-label="$t('aria.showFutureShifts')"
              />
              <b-icon-eye-slash
                  v-else
                  :aria-label="$t('aria.showFutureShifts')"
              />
            </b-btn>
          </h2>
          <div
              v-if="showFutureShifts">
            <b-row
                v-for="(_shift, index) in shiftsInTheFuture"
                :key="index"
                align-h="center">
              <b-col
                  cols="*"
                  align="center"
                  class="workDay__timecard__container px-5 my-3">
                <h3
                    v-text="displayShiftDate(_shift.date)"
                    class="workDay__timecard__header"
                />
                <b-row
                    key="time"
                    v-if="_shift.time && (_shift.time.start || _shift.time.end)"
                    align-h="center"
                    class="mb-4">
                  <b-col
                      cols="*"
                      align="center"
                      class="workDay__input__container__dark"
                      v-if="_shift.time && _shift.time.start">
                    <b
                        v-text="$t('workDay.shiftStartsAt')"
                    />
                    <br />
                    <b
                        class="px-3 largeText"
                        v-text="displayTime(now, _shift.time.start)"
                    />
                  </b-col>
                  <b-col
                      cols="*"
                      align="center"
                      v-if="_shift.time && _shift.time.end"
                      class="workDay__input__container__dark">
                    <b
                        v-text="$t('workDay.shiftEndsAt')"
                    />
                    <br />
                    <b
                        v-html="displayTime(now, _shift.time.end)"
                        class="px-3 largeText"
                    />
                  </b-col>
                </b-row>
                <b-row
                    key="projectInfo"
                    v-if="_shift.project || (_shift.projectData && (_shift.projectData.name || _shift.projectData.title || _shift.projectData.address.street))"
                    align-h="center"
                    class="my-3">
                  <b-col
                      cols="*"
                      align="center"
                      class="my-auto">
                    <div
                        class="workDay__input__container__dark">
                      <b
                          v-text="$t('workDay.shiftProject')"
                      />
                      <display-project-info
                          :shiftInfo="shift"
                          class="mt-2 px-3"
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row
                    key="shiftNotes"
                    v-if="_shift.notes"
                    align-h="center"
                    class="my-2">
                  <b-col
                      cols="*"
                      align="center">
                    <div
                        class="workDay__input__container__dark">
                      <b
                          v-text="$t('workDay.shiftNotes')"
                      />
                      <br />
                      <b
                          v-text="_shift.notes"
                          class="px-3 largeText"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div
          id="workHistory"
          class="workDay__input__container__dark mt-5">
        <div
            class="workDay__input__container">
          <h2
              class="text-center">
            {{$t('workDay.yourShiftHistory')}}
            <b-btn
                @click="showShiftHistory = !showShiftHistory">
              <b-icon-eye
                  v-if="!showShiftHistory"
                  :aria-label="$t('aria.showShiftHistory')"
              />
              <b-icon-eye-slash
                  v-else
                  :aria-label="$t('aria.showShiftHistory')"
              />
            </b-btn>
          </h2>
          <div
              v-if="showShiftHistory">
            <time-sheets
                :timecards="signIns"
                :employee="employee"
            />
          </div>
        </div>
      </div>
    </div>
    <div
        v-else
        key="loader"
        class="workDay__container__dark">
      <loader
          color="white"
          type="bars"
          :h="82"
          :w="202"
          :fullPage="false"
      />
    </div>
  </transition>
</template>

<script>
// Employee Timecard & Sign In History viewer
import moment from 'moment';
import totalCalculators from '@/mixins/totalCalculators';
import DisplayProjectInfo from '@/components/singlePurpose/DisplayWorkDayProjectInfo';
import TimeSheets from '@/components/workDay/TimeSheets';
import { sns } from '@/store/aws';

export default {
  title() {
    return `${this.$t('workDay.titles.timeCard')} | ${this.$t('project.brand')}`;
  },
  components: {
    DisplayProjectInfo,
    TimeSheets,
  },
  mixins: [
    totalCalculators,
  ],
  created() {
    this.$store.state.showLoader = true;
    this.$store.dispatch('initialLoad').catch((err) => {
      console.log(err);
      this.$router.go();
    });
    // this.$store.dispatch('timecardHistory').catch((err) => {
    //   console.log(err);
    //   this.$router.go();
    // });
    this.readyCheck();
    // Update current time every minute, beginning with the next whole minute
    setTimeout(() => {
      this.now = new Date();
      this.checkShiftLength();
      setInterval(() => {
        this.now = new Date();
        this.checkShiftLength();
      }, 60000);
    }, (moment(this.now).format('ss') - 60) * -1000);
  },
  computed: {
    id() {
      // Cognito Pool user id
      if (this.$store.state.data.userId) {
        return this.$store.state.data.userId;
      }
      return 'none';
    },
    initialLoad() {
      return this.$store.state.initialLoad;
    },
    clockInNow() {
      const minutesBefore = this.preferences.shiftSignInEarly;
      let time;
      if (this.shift.time && this.shift.time.start) {
        time = this.shift.time.start.split(':');
      } else {
        time = [moment(this.now).format('HH'), moment(this.now).format('mm'), '00'];
      }
      const shiftDate = moment(this.now);
      shiftDate.hour(time[0]);
      shiftDate.minute(time[1]);
      shiftDate.second('00');
      shiftDate.millisecond('0');
      if ((minutesBefore >= moment(shiftDate).diff(this.now, 'minutes')) || this.shift.loginNow) {
        return true;
      }
      return false;
    },
    clockInAt() {
      const minutesEarly = this.preferences.shiftSignInAdvance;
      if (this.shift) {
        let time;
        if (this.shift.time && this.shift.time.start) {
          time = this.shift.time.start.split(':');
        } else {
          time = [moment(this.now).format('HH'), moment(this.now).format('mm')];
        }
        const shiftDate = moment(this.now);
        shiftDate.hour(time[0]);
        shiftDate.minute(time[1]);
        shiftDate.second('00');
        shiftDate.millisecond('0');
        if (minutesEarly >= moment(shiftDate).diff(this.now, 'minutes') && moment(shiftDate.diff(this.now, 'minutes')) > 1) {
          return true;
        }
      }
      return false;
    },
    employee() {
      if (this.$store.getters.employeeByToken(this.id)) {
        return this.$store.getters.employeeByToken(this.id);
      }
      return [];
    },
    openLogin() {
      if (this.shift) {
        return this.signIns.find(l => !l.clockOut
          && (l.shift === this.shift._id
              || l.anytimeLogin
              || l.adminLogin));
        // return this.signIns.find(l => l.employee === this.employee._id && (l.shift === this.shift._id || l.anytimeLogin) && !l.clockOut);
        // return this.shift.logins.find(l => l.employee === this.id && !l.clockOut);
      }
      return {};
    },
    preferences() {
      return this.$store.state.preferences;
    },
    shift() {
      let shift;
      if (this.shiftsToday.length) {
        shift = this.shiftsToday[this.viewShift];
      }
      return shift;
    },
    shiftsAssigned() {
      const shifts = [];
      this.$store.getters.workDays.forEach((shift) => {
        if (shift.employees.indexOf(this.employee._id) > -1) {
          shifts.push(shift);
        }
      });
      return shifts;
    },
    shiftsInTheFuture() {
      const futureShifts = [];
      const today = moment(this.now).format('L');
      this.shiftsAssigned.forEach((shift) => {
        shift.dates.forEach((date) => {
          if (moment(date).format('L') > today) {
            const _shift = { ...shift };
            _shift.date = date;
            futureShifts.push(_shift);
          }
        });
      });
      return futureShifts;
    },
    shiftsToday() {
      const shiftsToday = [];
      const today = moment(this.now).format('L');
      this.shiftsAssigned.forEach((shift) => {
        shift.dates.forEach((date) => {
          if (moment(date).format('L') === today) {
            shiftsToday.push(shift);
          }
        });
      });
      if (this.preferences.shiftSignInNoShift) {
        shiftsToday.push({
          _id: null,
          anytimeLogin: true,
          loginNow: true,
          time: {
            start: null,
            end: null,
          },
          logins: [],
        });
      }
      return shiftsToday;
    },
    signIns: {
      get() {
        if (this.$store.getters.employeeTimecards(this.employee._id)) {
          return this.$store.getters.employeeTimecards(this.employee._id);
        }
        return [];
      },
    },
    signInsToday() {
      let signInsToday = [...this.signIns].filter(p => moment(p.clockIn).format('L') === moment().format('L'));
      signInsToday = signInsToday.filter(p => p.shift === this.shift._id || (this.shift.anytimeLogin && !p.shift));
      return signInsToday;
    },
  },
  data() {
    return {
      addClockInNotes: false,
      autoPunchLunch: true,
      autoPunchedLunch: {
        start: null,
        end: null,
      },
      cancelClockIn: false,
      cancelSignInDisplay: null,
      clockInNotes: null,
      clockOutNotes: null,
      showFutureShifts: false,
      showLunchOption: false,
      justPunched: false,
      model: {},
      now: new Date(), // Updates once every minute via created()
      showShiftHistory: false,
      viewShift: 0,
    };
  },
  methods: {
    addNotes() {
      this.addClockInNotes = !this.addClockInNotes;
      if (!this.addClockInNotes) {
        this.clockInNotes = null;
      }
    },
    cancelPunch() {
      this.cancelClockIn = true;
    },
    checkShiftLength() {
      // Return True if app should display an auto-clockout option for lunch break
      if (this.openLogin
          && this.openLogin.clockIn
          && !this.openLogin.clockOut
          && !this.showLunchOption) {
        const lengthOfShift = moment().diff(this.openLogin.clockIn, 'hours');
        if (lengthOfShift >= 5) {
          this.autoPunchedLunch.start = moment(this.openLogin.clockIn).add(240, 'minutes').format('HH:mm');
          this.autoPunchedLunch.end = moment(this.openLogin.clockIn).add(270, 'minutes').format('HH:mm');
          this.showLunchOption = true;
        }
      }
    },
    displayHoursWorked(signIn) {
      return `<b>${(moment(signIn.clockOut).diff(signIn.clockIn, 'minutes') / 60).toFixed(2)}</b> ${this.$t('hours')}`;
    },
    displayShiftDate(date) {
      return moment(date)
        .format('LLLL')
        .split(' ')
        .slice(0, 3)
        .join(' ')
        .slice(0, -1); // Remove trailing comma
    },
    displayTimecardPunch(log, clockIn) {
      const time = clockIn ? log.clockIn : log.clockOut;
      let string = `<small>${moment(time).format('l')}</small> <b class="mx-4">${moment(time).format('hh:mm a')}</b>`;
      string += ` ${clockIn ? this.$t('workDay.clockedIn') : this.$t('workDay.clockedOut')}`;
      if (clockIn && log.clockInNotes) {
        string += `<br /><small class="ml-3 font-weight-bold">${this.$t('workDay.note')}:</small> ${log.clockInNotes}`;
      }
      if (!clockIn && log.clockOutNotes) {
        string += `<br /><small class="ml-3 font-weight-bold">${this.$t('workDay.note')}:</small> ${log.clockOutNotes}`;
      }
      return string;
    },
    async notifyOfClockIn(log) {
      if (this.preferences.shiftSignInNotify) {
        const employee = this.$store.getters.employee(log.employee);
        const nameKey = this.$store.state.account.employeesFields.name;
        const name = employee[nameKey];
        let message = '';
        if (name) {
          message += `${name}\n`;
        }
        if (log.clockIn) {
          message += `${this.$t('workDay.clockedIn')}: ${moment(log.clockIn).format('hh:mm a')}`;
          if (log.clockInNotes) {
            message += '\n';
            message += log.clockInNotes;
          }
          message += '\n\n';
        }
        if (log.clockOut) {
          message += `${this.$t('workDay.clockedOut')}: ${moment(log.clockOut).format('hh:mm a')}`;
          if (log.clockOutNotes) {
            message += `\n${log.clockOutNotes}`;
          }
          const lengthOfShift = moment(log.clockOut).diff(log.clockIn, 'minutes');
          if (log.autoPunchedLunch && log.autoPunchedLunch.start) {
            message += '\n\n';
            const lengthOfLunch = moment(log.autoPunchedLunch.end).diff(log.autoPunchedLunch.start, 'minutes');
            message += `${lengthOfLunch} ${this.$t('workDay.lunchReported')}`;
          } else if (lengthOfShift > 240) {
            message += '\n\n';
            message += this.$t('workDay.noLunch');
          }
        }
        const params = {
          Message: message,
          PhoneNumber: this.formatPhoneForSns(this.preferences.shiftSignInNotify),
        };
        sns.setSMSAttributes({
          attributes: {
            DefaultSMSType: 'Transactional',
          },
        });
        await sns.publish(params).promise().catch((err) => {
          console.log(err);
        });
      }
    },
    punchTimecard(shift, aheadOfTime) {
      if (!this.justPunched) {
        this.justPunched = true;
        setTimeout(() => {
          this.justPunched = false;
        }, 5000);
        if (this.openLogin) {
          const log = this.openLogin;
          if (this.showLunchOption && this.autoPunchLunch) {
            const start = moment();
            start.hour(this.autoPunchedLunch.start.split(':')[0]);
            start.minute(this.autoPunchedLunch.start.split(':')[1]);
            start.second('00');
            start.millisecond('0');
            const end = moment();
            end.hour(this.autoPunchedLunch.end.split(':')[0]);
            end.minute(this.autoPunchedLunch.end.split(':')[1]);
            end.second('00');
            end.millisecond('0');
            log.autoPunchedLunch = {
              start: new Date(start),
              end: new Date(end),
            };
            this.autoPunchedLunch = {
              start: null,
              end: null,
            };
            this.showLunchOption = false;
            this.autoPunchLunch = false;
          }
          log.clockOut = this.now;
          const i = this.signIns.findIndex(s => s.shift === shift._id && !s.clockOut);
          if (i > -1) {
            this.signIns[i].clockOut = this.now;
            if (this.clockOutNotes) {
              this.signIns[i].clockOutNotes = this.clockOutNotes;
            }
          }
          this.notifyOfClockIn(log);
          this.$store.dispatch('clockOut', log);
        } else {
          const log = {
            employee: this.employee._id,
            shift: shift._id,
            clockIn: null,
            clockOut: null,
            clockInNotes: null,
            clockOutNotes: null,
          };
          let clockInTime = this.now;
          if (aheadOfTime) {
            clockInTime = shift.time.start;
            log.clockInClicked = this.now;
          }
          log.clockIn = clockInTime;
          if (this.clockInNotes) {
            log.clockInNotes = this.clockInNotes;
          }
          this.notifyOfClockIn(log);
          this.$store.dispatch('clockIn', log);
        }
        if (this.clockInNotes) {
          this.clockInNotes = null;
        }
        if (this.addClockInNotes) {
          this.addClockInNotes = false;
        }
      }
    },
  },
  watch: {
    initialLoad(newVal) {
      if (newVal) {
        this.$store.state.showLoader = false;
        this.checkShiftLength();
      }
    },
  },
};
</script>
