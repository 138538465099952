import Vue from 'vue';
import Router from 'vue-router';
import AuthenticationPortal from '@/views/AuthenticationPortal';
// import InvoiceAdmin from '@/views/data/admin/InvoiceAdmin';
// import CollectionAdmin from '@/views/data/admin/CollectionAdmin';
// import RecordAdmin from '@/views/data/admin/RecordAdmin';

// WorkDay
import TimeCard from '@/views/workDay/TimeCard';
// import TimeOptions from '@/views/workDay/TimeOptions';
// import WorkDayDash from '@/views/workDay/WorkDayDash';
// import EmployerTimecards from '@/views/workDay/EmployerTimeSheets';

// import SS from '@/views/data/admin/GeneralAvailability';
// import CollectionViewer from '@/views/data/admin/CollectionViewer';
import PrivacyPolicy from '@/views/util/privacy';

// import DownloadGenie from '@/views/util/Downloader';
import _app from './AppData';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: '/dashboard/employee/timecard',
    },
    {
      path: '/dashboard',
      component: AuthenticationPortal,
      children: [
        _app.switch.workDay ? {
          path: 'employee',
          component: TimeCard,
          children: [
            {
              path: 'timecard',
              component: TimeCard,
            },
          ],
        } : {},
      ],
    },
    {
      path: '/privacy',
      component: PrivacyPolicy,
    },
  ],
});


export default router;
