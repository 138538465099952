export const dataGetters = {
  allInvoices: state => state.data.invoices,
  invoice: state => (id) => {
    if (state.data.invoices.find(i => i._id === id)) {
      return state.data.invoices.find(i => i._id === id);
    }
    return {};
  },
  invoicesByCustomer: state => (id) => {
    if (state.data.invoices.filter(i => i.customer === id)) {
      return state.data.invoices.filter(i => i.customer === id);
    }
    return [];
  },
  invoiceCollection: state => state.data.collections.filter(c => c.systemInvoices)[0]._id,
  allCustomers: state => state.data.customers,
  customerById: state => id => state.data.customers.find(customer => customer._id === id),
  customer: state => id => state.data.customers.find(customer => customer._id === id),
  customerHasBeenAdded: state => state.data.customerHasBeenAdded,
  customerPreferences: state => id => state.data.customers.find(customer => customer._id === id).preferences,
  record: state => (collId, recId) => {
    if (state.data.records[collId]) {
      const records = state.data.records[collId];
      if (records.find(record => record._id === recId)) {
        return records.find(record => record._id === recId);
      }
    }
    return {};
  },
  recordLink: state => (collId, rec, fieldsReq) => {
    if (state.data.records[collId]) {
      const collection = state.data.collections.find(coll => coll._id === collId);
      let record;
      if (!rec._id) {
        record = state.data.records[collId].find(recs => recs._id === rec);
      } else {
        record = rec;
      }
      const recordLink = {};
      let fields;
      if (fieldsReq === 'all') {
        const allFields = [];
        Object.entries(collection.schemaObj).forEach((i) => {
          allFields.push({
            _id: i[0],
            metaType: i[1].metaType,
          });
        });
        fields = allFields;
      } else {
        fields = fieldsReq;
      }
      fields.forEach((ff) => {
        let f = ff;
        if (f && !f._id) {
          f = { _id: ff, metaType: collection.schemaObj[ff] ? collection.schemaObj[ff].metaType : 'None' };
        }
        if (collection.schemaObj[f._id]) {
          switch (f.metaType) {
            case ('None'): {
              console.log('unknown metatype', f, collection.schemaObj);
              break;
            }
            case ('Address'): {
              if (record[f._id] && record[f._id].address) {
                const ad = record[f._id].address;
                if (ad && (ad.attn || ad.street || ad.streetSecond || ad.city
                || ad.state || ad.zip || ad.country || ad.postal || ad.notes)) {
                  recordLink[f._id] = {
                    data: record[f._id],
                    label: collection.schemaObj[f._id].label,
                    metaType: collection.schemaObj[f._id].metaType,
                    humanId: record.humanId,
                  };
                }
              }
              break;
            }
            case ('Inventory'): {
              if (record[f._id] && record[f._id].counter !== null) {
                recordLink[f._id] = {
                  data: record[f._id],
                  label: collection.schemaObj[f._id].label,
                  metaType: collection.schemaObj[f._id].metaType,
                  humanId: record.humanId,
                };
              }
              break;
            }
            case ('Link'): {
              if (record[f._id] && record[f._id].linksChosen.length) {
                recordLink[f._id] = {
                  data: record[f._id],
                  label: collection.schemaObj[f._id].label,
                  metaType: collection.schemaObj[f._id].metaType,
                  humanId: record.humanId,
                };
              }
              break;
            }
            case ('MultiSelect'):
            case ('SelectOne'): {
              const options = [];
              if (record[f._id]) {
                Object.entries(record[f._id]).forEach((item) => {
                  if (item[1].value) {
                    options.push(item[1].label);
                  }
                });
              }
              if (options.length) {
                recordLink[f._id] = {
                  data: options,
                  label: collection.schemaObj[f._id].label,
                  metaType: collection.schemaObj[f._id].metaType,
                  humanId: record.humanId,
                };
              }
              break;
            }
            case ('Notes'): {
              break;
            }
            case ('Phone'): {
              if (record[f._id] && record[f._id].number) {
                recordLink[f._id] = {
                  data: record[f._id],
                  label: collection.schemaObj[f._id].label,
                  metaType: collection.schemaObj[f._id].metaType,
                  humanId: record.humanId,
                };
              }
              break;
            }
            case ('Price'): {
              if (record[f._id] && record[f._id].price) {
                recordLink[f._id] = {
                  data: record[f._id],
                  label: collection.schemaObj[f._id].label,
                  metaType: collection.schemaObj[f._id].metaType,
                  humanId: record.humanId,
                };
              }
              break;
            }
            case ('Status'): {
              if (record[f._id] && (record[f._id].text || record[f._id].urgent)) {
                recordLink[f._id] = {
                  data: record[f._id],
                  label: collection.schemaObj[f._id].label,
                  metaType: collection.schemaObj[f._id].metaType,
                  humanId: record.humanId,
                };
              }
              break;
            }
            case ('Tags'): {
              if (record[f._id] && record[f._id].length) {
                recordLink[f._id] = {
                  data: record[f._id],
                  label: collection.schemaObj[f._id].label,
                  metaType: collection.schemaObj[f._id].metaType,
                  humanId: record.humanId,
                };
              }
              break;
            }
            case ('Toggle'): {
              if (record[f._id] && (record[f._id].value || collection.schemaObj[f._id].default.labelOff)) {
                recordLink[f._id] = {
                  data: record[f._id],
                  label: collection.schemaObj[f._id].label,
                  metaType: collection.schemaObj[f._id].metaType,
                  humanId: record.humanId,
                };
              }
              break;
            }
            case ('Email'):
            case ('Number'):
            case ('String'):
            case ('Textarea'): {
              if (record[f._id]) {
                recordLink[f._id] = {
                  data: record[f._id],
                  label: collection.schemaObj[f._id].label,
                  metaType: collection.schemaObj[f._id].metaType,
                  humanId: record.humanId,
                };
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      });
      if (recordLink === {} || !Object.entries(recordLink).length) {
        return {};
      }
      return recordLink;
    }
    return {};
  },
  records: state => (id) => {
    const collection = state.data.collections.find(coll => coll._id === id);
    if (collection && state.data.records[id]) {
      return state.data.records[id];
    }
    return [];
  },
};
export const utilityGetters = {
  account: state => (request) => {
    if (request) {
      return state.account[request];
    }
    return null;
  },
  collection: state => id => state.data.collections.filter(coll => coll._id === id)[0],
  collections: state => state.data.collections.filter(c => !c.systemInvoices
    && !c.systemCustomers
    && !c.systemPreferences
    && !c.systemSchedule
    && !c.systemWorkDays
    && !c.systemWorkDaysTimecards),
  fields: state => (id, requestType) => {
    const collection = state.data.collections.find(coll => coll._id === id);
    const schema = collection && collection.schemaObj ? collection.schemaObj : null;
    const fields = [];
    if (schema) {
      switch (requestType) {
        case ('admin'): {
          return Object.entries(schema);
        }
        case ('display'): {
          fields.push({
            key: 'idDisplay',
            label: '',
            idDisplay: collection.idDisplay,
            display: true,
            download: collection.idDownload,
            metaType: collection.idType,
            default: null,
            stickyColumn: true,
          });
          Object.entries(schema).forEach((field) => {
            const newField = {
              key: field[0],
              label: field[1].label,
              display: field[1].display,
              metaType: field[1].metaType,
              type: field[1].type,
              default: field[1].default,
            };
            fields.push(newField);
          });
          if (collection.dateAddedDisplay) {
            fields.push({
              key: 'dateAdded',
              label: 'Date Added', // TODO - localize this!
              display: true,
              download: collection.dateAddedDownload,
              metaType: 'SystemDate',
            });
          }
          if (collection.dateUpdatedDisplay) {
            fields.push({
              key: 'dateUpdated',
              label: 'Updated', // TODO - localize this!
              display: true,
              download: collection.dateUpdatedDownload,
              metaType: 'SystemDate',
            });
          }
          fields.push({ key: 'actionButtons', label: '', display: true });
          return fields.filter(field => field.display);
        }
        case ('download'): {
          if (collection.idDownload && collection.idType !== 'noId') {
            fields.push(['id', {
              key: collection.idType,
              label: 'id',
              display: collection.idDisplay,
              download: collection.idDownload,
              metaType: 'id',
              default: null,
            }]);
          }
          Object.entries(schema).forEach((field) => {
            if (field[1].download) {
              fields.push(field);
            }
          });
          if (collection.dateAddedDownload) {
            fields.push(['dateAdded', {
              metaType: 'SysDate',
              key: 'dateAdded',
              label: 'Date Added', // TODO -- localize
              download: true,
              default: null,
            }]);
          }
          if (collection.dateUpdatedDownload) {
            fields.push(['dateUpdated', {
              metaType: 'SysDate',
              key: 'dateUpdated',
              label: 'Date Updated', // TODO -- localize
              download: true,
              default: null,
            }]);
          }
          return fields;
        }
        case ('linked'): {
          Object.entries(schema).forEach((field) => {
            if (field[1].metaType === 'Address') {
              fields.push({ ...field, addressDisplayType: 'whole' });
            } else if (field[1].metaType !== 'Link') {
              fields.push(field);
            }
          });
          return fields;
        }
        case ('form'): {
          Object.entries(schema).forEach((field) => {
            if (field[0] !== '_collection'
            && field[0] !== 'linkedIn'
            && field[0] !== 'invoicedIn'
            && field[0] !== 'dateAdded'
            && field[0] !== 'dateUpdated'
            && field[0] !== 'humanId') {
              fields.push(field);
            }
          });
          return fields;
        }
        default: {
          Object.entries(schema).forEach((field) => {
            fields.push(field);
          });
          return fields;
        }
      }
    } else {
      return [];
    }
  },
  schema: state => (collId) => {
    const collection = state.data.collections.find(coll => coll._id === collId);
    return collection && collection.schemaObj ? Object.entries(collection.schemaObj) : {};
  },
  retry: state => state.retry,
  spinnerById: state => state.spinnerById,
  toastData: state => state.showToast,
  userPreferences: state => state.preferences,
};
export const workDayGetters = {
  employees: (state) => {
    const employeesId = state.account.employees;
    if (employeesId) {
      return state.data.records[employeesId];
    }
    return [];
  },
  employee: state => (id) => {
    const employeesColl = state.account.employees;
    if (employeesColl) {
      return state.data.records[employeesColl].find(i => i._id === id);
    }
    return [];
  },
  employeeByToken: state => (token) => {
    // Find employee data using Cognito Pool user key
    const employeesColl = state.account.employees;
    if (employeesColl && state.account.team.length) {
      const employeeId = state.account.team.find(member => member.idToken === token);
      console.log(token, state.data.records[employeesColl].find(i => i._id === employeeId.record));
      return state.data.records[employeesColl].find(i => i._id === employeeId.record);
    }
    return [];
  },
  employeeTimecards: state => id => state.data.workDayTimecards.filter(tc => tc.employee === id),
  timecardById: state => id => state.data.workDayTimecards.find(tc => tc._id === id),
  project: state => id => state.data.invoices.find(i => i._id === id),
  projects: (state) => {
    state.data.invoices.forEach(i => console.log(i.status));
    return state.data.invoices;
  },
  projectsByPage: state => (page) => {
    state.data.invoices.forEach(i => console.log(i.status));
    return state.data.invoices.filter(a => a.status === 'open').reverse().slice(((page - 1) * 3), page * 3);
  },
  workDays: state => state.data.workDays,
  workDayById: state => id => state.data.workDays.find(day => day._id === id),
};
