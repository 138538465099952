/*  This file allows for global reference. Used to customize
    site options. Called with $_app */
import secrets from '../secrets.json';

export default {
  offline: secrets.offline,
  switch: {
    collectionAdminAccess: false,
    collectionAdminLimit: 5,
    scheduler: false,
    navbar: false,
    nav: {
      brand: false,
      brandBottom: false,
      cartButton: false,
      enableButtons: false,
      // Use a maximum of one type of Link (or none, for an unclickable brand)
      // Links are set in $t('project.brandNavbar.hrefLink')
      hrefLink: false,
      homeIcon: true,
      linksRight: false,
      mainMenu: true,
      restartAppLink: true,
      routerLink: false,
      settingsButton: true,
    },
    foot: {
      createdBy: true,
    },
    footer: false,
    footerInMenu: true,
    banner: false, // enables the IntroBanner.vue component.
    bannerButton: false, // action button that appears in IntroBanner
    bannerAboveNavbar: true,
    workDay: true,
  },
  taxRate: 0.0725,
  // Enable to show 'Taxable' & 'Qty' option in invoice generator
  invoiceDateOption: false,
  invoiceDatePaymentsOption: true,
  invoiceTaxOption: false,
  invoiceQtyOption: false,
  invoice: {
    defaultType: 'Invoice',
    deliveryMethods: [
      'Email',
      'Print',
      'Download',
    ],
    displayDiscounts: false,
    showToastOnSuccessfulSave: true,
    paymentOptions: [
      { value: null, text: '' },
      'Check',
      'Cash',
      'Credit Card',
      'Online Payment',
      'Credit/Refund',
      'System',
    ],
    showTimes: false,
    statusOptions: [
      'Open', // always 0
      'Sent', // always 1
      'Paid', // always 2
      'Past Due', // always 3
      'Hold', // always 4!
      'Proposal',
    ],
  },
};
